.virtual-keyboard-container {
    position: absolute;
    width: 100%;
    z-index: 1000;
    bottom: 0px;
}

.virtual-keyboard-container .hg-button {
    font-size: 25px;
    height: 60px;
}