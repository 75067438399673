.booking-left-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.booking-field-row {
  display: flex;
  flex-direction: row;
}
