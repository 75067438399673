@media (aspect-ratio: 16/9) {
  .language-picker {
    position: absolute;
    display: inline-block;
    left: 50%;
    top: 0.5vh;
    transform: translateX(-50%);
  }
}

@media (aspect-ratio: 9/16) {
  .language-picker {
    position: absolute;
    display: inline-block;
    left: 50%;
    top: 1vh;
    transform: translateX(-50%);
  }
}

.dropdown {
  margin-top: 20px;
  position: absolute;
  top: 50px;
  left: 0;
  gap: 10px;
  z-index: 50;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.language-list {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.language-item {
  width: 80px;
  height: 60px;
  border-radius: 25%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.language-item.active {
  /* Highlight the active language */
  /* border: 5px solid #007bff;  */
}

.language-item .fi {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
