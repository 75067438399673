.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dark background overlay */
  display: flex;
  justify-content: center;
  align-items: center; /* Center the modal */
  transition: background-color 0.3s ease;
}

.modal {
  background-color: white;
  padding: 40px 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  text-align: center;
  font-size: 20px;
  transform: translateY(100vh); /* Start from the bottom */
  animation: slide-in-to-center 0.3s ease forwards;
}

.modal-title {
  margin-bottom: 10px;
  font-size: 25px;
}

.modal-text {
  margin-bottom: 30px;
}

.modal-button {
  padding: 10px 0;
  width: 100px;
  background-color: #f5f5f5;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
}

.modal-button:active {
  background-color: #e1e1e1;
}

/* Keyframes for sliding in and out */
@keyframes slide-in-to-center {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
