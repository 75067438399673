.select-client-group-horizontal-wide-container {
  text-align: center;
  font-family: Montserrat, sans-serif;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
}

.select-client-group-horizontal-wide-title-container {
  height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Centers horizontally */
  justify-content: center;
  /* Centers vertically */
  margin: 40px 0 0 0;
  text-align: center;
  /* Optional: Center the text inside the divs */
}

.welcome-text {
  margin: 0 auto;
  font-size: 24px;
  margin-bottom: 20px;
  background-color: rgb(230, 230, 230);
  border-radius: 10px;
  padding: 20px 0;
  font-weight: bold;
}

@media (aspect-ratio: 16/9) {
  .button-grid {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    max-height: 60vh;
    overflow: scroll;
    scrollbar-width: none;
  }
}

@media (aspect-ratio: 9/16) {
  .button-grid {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    max-height: 80vh;
    overflow: scroll;
    scrollbar-width: none;
  }
}

.button-grid::-webkit-scrollbar {
  display: none;
}

.image-button {
  max-width: 100%;
  margin: 10px 0;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
}

.image-button img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}

.other-buttons-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-end;
}

.other-button {
  font-size: 17px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  background-color: #f5f5f5;
  width: 150px;
  text-align: right;
}

.other-button:active {
  background-color: #e1e1e1;
}
