.company-selector-root-container {
  width: 95vw;
  margin: auto;
  height: 50vh;
}

.top-container {
  margin-bottom: 50px;
  text-align: center;
}

.alphabet-button {
  padding: 10px 15px;
  margin: 2px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: "#000";
  font-weight: "normal";
}

.all-button {
  padding: 10px 15px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #007bff;
  background-color: #fff;
  color: #007bff;
  font-weight: normal;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 15px;
  min-height: 25vh;
}

.pagination-controls-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.pagination-scroll-button {
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0.6);
}
