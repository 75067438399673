.Select-client-portrait-cards-outer-container {
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.Select-client-portrait-cards-inner-container {
  height: 90%;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.Select-client-portrait-cards-header {
  flex: 2;
  display: flex;
}

.Select-client-portrait-cards-search {
  display: flex;
  align-items: center;
}

.Select-client-portrait-cards-card-container {
  flex: 15;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 20px;
  column-gap: 20px;
  overflow-y: auto;
  grid-auto-rows: min-content;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Select-client-portrait-cards-card-container::-webkit-scrollbar {
  display: none;
}

.Select-client-portrait-cards-card-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.Select-client-portrait-cards-card {
  box-sizing: border-box;
  overflow: hidden;
}

.Select-client-portrait-cards-search-input {
  flex: 1;
}

.Select-client-portrait-cards-logo {
  flex: 1;
  height: 100%;
  width: 50%;
}
.Select-client-portrait-cards-logo img {
  max-height: 100%;
  max-width: 100%;
}

.Select-client-portrait-cards-search-input {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
}

.search-input {
  padding-left: 40px;
  font-size: 15px;
  background-color: white;
}

.search-icon {
  position: absolute;
  left: 15px;
  color: #ccc;
  font-size: 1rem;
}

.search-input:focus {
  outline: none;
  border-color: #007bff;
}

.client-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Select-client-portrait-cards-home-button {
  flex: 1;
}
