.root-container {
  display: flex;
  gap: 20px;
  width: 90%;
  height: 60vh;
  margin-top: 10vh;
}

.back-button {
  align-self: flex-start;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: white;
  color: black;
  border-radius: 4px;
  position: absolute;
  top: 7vh;
  left: 5vw;
}

.left-side-company-name {
  font-size: 3vw;
  margin-bottom: 20px;
  flex: 1;
}

.left-side-location-text {
  font-size: 2vw;
  flex: 2;
}

.location-pin {
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #007bff;
  /* Pin color */
  border: 2px solid white;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
  transform: translate(-50%, -110%);
  margin-bottom: 10px;
}

/* Create the triangular pointer of the pin */
.location-pin::after {
  content: "";
  position: absolute;
  bottom: -12px;
  /* Position the triangle below the circle */
  left: 50%;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #007bff;
  /* Match the color of the pin */
  transform: translateX(-50%);
}

/* Create a circular inner dot inside the pin */
.location-pin::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.location-pin.target {
  background-color: red;
}

.location-pin.start {
  background-color: green;
}

.location-pin.start.example {
  position: relative;
  display: inline-block;
  transform: none;
  left: 10px;
}

.left-side-you-are-here-text {
  display: inline-block;
  vertical-align: middle;
  font-size: 2vw;
}

.left-side-root-container {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.left-side-company-and-location-container {
  flex: 4;
}

.left-side-you-are-here-container {
  flex: 2;
  display: flex;
  align-items: center;
}

.left-side-buttons-container {
  flex: 5;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}

.left-side-button {
  padding-left: 20px;
  font-size: 1vw;
  font-weight: 900;
  text-align: left;
  height: 20%;
  width: 80%;
  border-radius: 10px;
}

.left-side-line {
  height: 2px;
  background-color: black;
  width: 100%;
  margin: 20px 0;
}

.right-side-container {
  flex: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.right-side-map-container {
  position: relative;
  width: 100%;
  /* 16:9 aspect ratio for container */
  padding-top: 56.25%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-side-map-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
