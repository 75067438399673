.calendar-day-view {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

/* Header row with time labels */
.header-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.calendar-title-header {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-slots-header {
  display: flex;
  flex: 9;
  flex-direction: row;
}

.header-cell {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Calendar rows */
.calendar-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.calendar-title {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.time-slots {
  display: flex;
  flex: 9;
  flex-direction: row;
}

.time-slot {
  flex: 1;
  min-height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.booking {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f8cbae;
}

.booking-person {
  overflow: hidden;
  text-overflow: ellipsis;
}

.booking-empty {
  width: 100%;
  height: 100%;
  background-color: #c5deb4;
}
